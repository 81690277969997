@use "sass:math";
@import "./mixins";
@import "node_modules/bulma/bulma";
@import "./colors";
@import "./buttons";
@import "./global";
@import "./fonts";
@import "./card";
@import "./dialogs";
@import "./checkboxes";
@import "./rate-app";
@import "src/scss/main";

body {
  background: white !important;
  color: $new_desc_color !important;
  font-weight: 400 !important;
  font-size: 14px;

  @include mobile {
    font-size: 12px;
  }
}

body,
button,
input,
select,
textarea {
  font-family: "Poppins", "Open Sans", "Helvetica Neue", sans-serif !important;
}

body[_ngcontent-c9],
button[_ngcontent-c9],
input[_ngcontent-c9],
select[_ngcontent-c9],
textarea[_ngcontent-c9] {
  font-family: "Poppins", "Open Sans", "Helvetica Neue", sans-serif !important;
}

.mat-checkbox,
.mat-select,
.mat-radio-button,
.mat-tab-label,
.mat-tab-link,
.mat-option {
  font-family: "Poppins", "Open Sans", "Helvetica Neue", sans-serif !important;
}

body {
  width: 100%;
  min-height: 100%;

  .app {
    height: 100%;
  }
}

input,
button {
  &:active,
  &:focus {
    outline: none !important;
  }
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba($prim_color_page, 0.26) !important;
}

.app {
  a {
    @include transition(all 0.2s ease-out);
    text-decoration: underline;
    color: $dark_text;
  }
}

input {
  height: 45px;
  width: 100%;
  background: $input_color;
  border: 2px solid $input_color;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: $dark_text;
  @include transition(all 0.3s ease-out 0s);
}

// input / textarea placeholder color
*::-webkit-input-placeholder {
  color: $placeholder_color;
}

*:-moz-placeholder {
  color: $placeholder_color;
}

*::-moz-placeholder {
  color: $placeholder_color;
}

*:-ms-input-placeholder {
  color: $placeholder_color;
}

a {
  color: $text_color;
  font-size: 14px;
}

.bg-wrapper {
  background: $gray_bg;
}

.alert {
  padding: 0.5rem 1.25rem !important;
  border-radius: 0 !important;
  opacity: 0.7;
  text-align: center;
}

//material elements styles

snack-bar-container {
  background: rgba(0, 0, 0, 0.65) !important;

  @include until($tablet) {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .mat-simple-snackbar {
    text-align: center;
    display: block;
  }
}

.mat-snack-bar-container.mat-snack-bar-center {
  margin: 0 auto !important;
}

.cdk-overlay-pane.mat-snack-bar-handset {
  @include until($tablet) {
    height: 0;
  }
}

// tooltip

.mat-tooltip {
  @include mobile {
    margin-left: 2px !important;
    margin-right: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

//success
.success-box {
  padding: 5px;
  background: transparent;
  border: 1px solid $prim_color_page;
  margin: 10px 0;
  font-size: 13px;
  text-align: center;
  color: $prim_color_page;
}

//alert

.alert-box {
  padding: 5px;
  background: transparent;
  border: 1px solid $red_re;
  margin: 10px 0;
  font-size: 13px;
  text-align: center;
  color: $red_re;

  &--info {
    background: #d8edf7;
    color: #31708f;
    border: 1px solid transparent;
  }

  &--empty {
    background: $gray_bg;
    border: 1px solid transparent;
    color: $alert_text;
    padding: 20px 0;
    font-size: 14px;
    margin: 15px 0;
    font-weight: 500;
  }
}

// MATERIAL ELEMENTS:

// dialog

// .cdk-overlay-dark-backdrop + .cdk-global-overlay-wrapper {

//   & > .cdk-overlay-pane {
//     width: 100%;
//   }
// }

@include until($tablet) {
  .custom-modalbox {
    .mat-dialog-container {
      padding-bottom: 85px;
      position: relative;
    }
  }
}

.cdk-global-overlay-wrapper > .cdk-overlay-pane {
  max-width: 100% !important;
  display: block;
}

mat-dialog-container.mat-dialog-container {
  max-width: 950px !important;
  margin: auto;
  width: 100%;
  overflow-x: hidden !important;

  @include tablet {
    max-height: 95vh;
    width: 75vw !important;
  }
}

.mat-coupons-popup {
  mat-dialog-container.mat-dialog-container {
    width: 100% !important;
    max-width: 558px !important;
    height: auto !important;
    padding: 0 !important;
    border-radius: 24px;
  }
}

.appeal-decision-dialog,
.send-new-message-dialog,
.send-attachment-dialog {
  mat-dialog-container.mat-dialog-container {
    width: 620px !important;
  }
}

@include until($tablet) {
  mat-dialog-container.mat-dialog-container {
    transform: none;
    opacity: 1;
    max-width: calc(100% - 20px) !important;
    border-radius: 10px !important;
    margin-top: 50px;
    margin-bottom: 20px;
    height: auto !important;
    max-height: calc(100% - 75px) !important;
  }
}

.cdk-global-overlay-wrapper {
  @include until($tablet) {
    align-items: inherit !important;
  }
}

.dialog-box {
  margin: -24px;

  &__header {
    padding: 12px 12px 0;
    text-align: right;
  }

  &__content {
    padding: 0 40px 25px;
    margin: auto;

    @include until($tablet) {
      padding: 20px;
    }

    .title {
      text-align: center;
    }

    .title,
    .description {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__buttons {
    text-align: right;

    button > * {
      margin: 0 10px;
    }
  }

  &__form {
    padding-top: 15px;

    &-buttons {
      padding: 30px 0 1px;
      text-align: right;
      @include until($tablet) {
        text-align: center;
      }
    }

    &-content {
      @include until($tablet) {
        max-height: inherit;
        overflow-y: inherit;
      }
    }
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.95;
  &.refund_dialogbox {
    opacity: 0;
  }
}

.mat-select {
  color: $new_desc_color;
  background: $input_color;
  height: 50px;
  border: 1px solid $input_border_color;
  border-radius: 4px;
  background-color: $input_color;
  padding: 0;
  font-size: 22px;

  .mat-select-arrow,
  .mat-select-disabled .mat-select-value,
  .mat-select-value-text {
    color: $dark_text !important;
  }

  .mat-select-underline {
    background-color: white !important;
  }

  .mat-select-trigger {
    width: 100%;
    height: 100%;
  }
  .mat-select-value {
    width: calc(100% - 40px);
    padding: 8px 10px;
    color: #e4e4e4 !important;
  }

  .mat-select-value {
    width: calc(100% - 40px);
    padding: 7px 12px;
  }

  .mat-select-arrow-wrapper {
    position: relative;
    width: 40px;
    .mat-select-arrow {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 10px;
      height: 2px;
      background-color: $prim_color_page;
    }
    &::before {
      left: calc(50% - 8px);
      transform: translateY(-50%) rotate(45deg);
    }
    &::after {
      right: calc(50% - 8px);
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  // .mat-select-arrow {
  //   position: relative;
  //   border-width: 8px;
  // }

  .mat-select-value,
  .mat-select-placeholder {
    top: -3px;
  }

  .mat-select-placeholder {
    width: 100% !important;
    line-height: 1;
  }
}

.mat-floating-placeholder {
  opacity: 0;
  visibility: hidden;
}

.employerStage {
  .mat-select {
    font-size: 20px;
    .mat-select-trigger {
      width: 100%;
      height: 100%;
    }
    .mat-select-value {
      width: calc(100% - 40px);
      padding: 8px 10px;
    }
    .mat-select-arrow-wrapper {
      position: relative;
      width: 40px;
      .mat-select-arrow {
        display: none;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 10px;
        height: 2px;
        background-color: $prim_color_page;
      }
      &::before {
        left: calc(50% - 8px);
        transform: translateY(-50%) rotate(45deg);
      }
      &::after {
        right: calc(50% - 8px);
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
}
@include media_from_to(768px, 1366px) {
  .employerStage {
    .mat-select {
      font-size: 16px;
      .mat-select-value {
        padding: 11px 10px;
      }
    }
  }
}

@include media_xs {
  .mat-select {
    font-size: 20px;
    .mat-select-value {
      padding: 7px 10px;
    }
  }

  .employerStage {
    .mat-select {
      font-size: 20px;
      .mat-select-value {
        padding: 7px 10px;
      }
    }
  }
}

// radio button

.mat-radio-inner-circle {
  background-color: $prim_color_page !important;
}

.mat-radio-outer-circle {
  border-color: $new_desc_color !important;
}

.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: $prim_color_page !important;
  }

  .mat-radio-inner-circle {
    border-color: $prim_color_page !important;
  }

  .mat-radio-inner-circle {
    border-color: $prim_color_page !important;
    transform: scale(0.3) !important;
  }
}

.title {
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 35px;
  color: $new_desc_color !important;
  line-height: 1.3;
  letter-spacing: 0.66px;

  @include until($desktop) {
    font-size: 30px;
  }

  @include until($tablet) {
    font-size: 18px;
    letter-spacing: 0.36px;
  }

  &__stage {
    display: block;
  }

  & * {
    font-weight: 600 !important;
  }
}

.description {
  font-size: 18px;
  letter-spacing: 0.36px;
  color: $new_desc_color;

  @include until($desktop) {
    font-size: 13px;
  }

  @include until($tablet) {
    font-size: 11px;
  }
}

.return-btn {
  color: $gray;
  font-size: 20px;
  font-weight: 400;
  float: right;
  display: block;
  padding: 5px;
  margin: 4px;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    @include transform(rotate(45deg));
    border-left: 2px solid $gray;
    border-bottom: 2px solid $gray;
    left: -12px;
    top: 13px;
    @include transition(all 0.2s ease-out);
  }

  &:hover {
    &:before {
      left: -20px;
    }
  }
}

.is-bold {
  font-weight: bold !important;
}

.is-black {
  color: black !important;
}

.is-italic {
  font-style: italic;
}

.is-normal {
  font-weight: 400 !important;
}

.is-medium {
  font-size: 17px !important;

  @include until($tablet) {
    font-size: 14px !important;
  }
}

.is-big {
  font-size: 40px !important;
}

.is-small {
  font-size: 12px !important;
}

//side-nav

.mat-sidenav.mat-sidenav-opened:not(.mat-sidenav-side),
.mat-sidenav.mat-sidenav-opening:not(.mat-sidenav-side) {
  box-shadow: none !important;
  background: $gray_bg;
}

.mat-sidenav-content {
  transform: inherit !important;
  min-height: 100vh !important;
}

.step-container {
  margin: 0 !important;
}

// mobile form columns

@include until($desktop) {
  .columns.columns--form {
    margin-bottom: 15px;

    .column.column--form {
      margin: 0;
      padding-bottom: 0;
    }
  }
}

// .payment-checkout__payments {
//   mat-radio-button:not(:last-of-type) {
//     padding-right: 30px;
//   }
// }

.mat-sidenav-backdrop {
  z-index: 3 !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $prim_color_page;
}

// Styles for specific dialogs:
@include until($tablet) {
  .login_dialog_wrap {
    app-login-dialog {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding: 24px;
    }
    .mat-dialog-container {
      margin: 0;
      max-width: 100vw !important;
      max-height: 100vh !important;
      width: 100vw;
      height: 100vh !important;
      padding: 0;
      border-radius: 0 !important;
    }
  }
}

.send-statement-onfido-dialog,
.taxcard-quality-info-dialog {
  .mat-dialog-container {
    background-color: $prim_color_page;

    @include desktop {
      width: 60vw !important;
      max-width: 740px !important;
    }
  }
}

.taxcard-quality-info-dialog .mat-dialog-container {
  @include mobile {
    height: 100vh;
    padding: 0;
  }
}

.backdropBackground {
  background-color: rgba(0, 0, 0, 0.288);
}

img.illustration-desktop {
  display: block;
  width: 100%;
  @include mobile {
    display: none !important;
  }
}

img.illustration-mobile {
  display: none;
  @include mobile {
    display: block !important;
  }
}

.question {
  .mat-checkbox-frame {
    border-color: $prim_color_page;
  }
}
@include until($tablet) {
  .question {
    .mat-checkbox-layout .mat-checkbox-label {
      line-height: 1.2 !important;
      position: relative;
      top: 6px;
    }
    .mat-checkbox-inner-container {
      margin-top: 0;
    }
  }
  .cdk-global-overlay-wrapper > .cdk-overlay-pane {
    width: 100%;
  }
}
.thank-you__resign-from-contract,
.question_bottom {
  .mat-checkbox-label {
    color: #595857;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.35px;
    padding-top: 0;
    padding-bottom: 0;
    @include until($tablet) {
      font-size: 11px;
      letter-spacing: 0.28px;
      line-height: 1.2;
    }
  }
  .mat-checkbox-frame {
    border-color: $prim_color_page;
  }
}
.form-select.disabled {
  pointer-events: none;
}

.mat-checkbox-layout {
  .mat-checkbox-label {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.33px;
    line-height: 1.2;
    padding-top: 2px;
  }
}

.mat-checkbox-top {
  .mat-checkbox-inner-container {
    margin-top: 7px;
  }
}

@include media_xs {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }
}

.visible-xs {
  display: none;
}
@include media_xs {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}

///button///
.btn {
  &--primary {
    background-color: $prim_color_page !important;
    border-color: $prim_color_page !important;
    &:not(:disabled):hover {
      background-color: transparent !important;
    }
  }
}
///end button///

///checkout radios///
p {
  strong {
    color: $new_desc_color !important;
    font-weight: 600;
  }
}
.tax-advisor-additional-validation {
  mat-radio-button {
    display: block;
    margin-bottom: 14px;

    .mat-radio-label {
      white-space: normal;
      align-items: flex-start;

      .mat-radio-label-content {
        padding-left: 18px;
      }
      .mat-radio-container {
        margin-top: 5px;
      }
    }

    @include media_xs {
      .mat-radio-label-content {
        padding-left: 14px;
      }
      .mat-radio-container {
        margin-top: 3px;
      }
      &.mat-radio-checked .mat-radio-label-content {
        .desc {
          font-weight: 600;
        }
      }
    }
  }
}
.pay_grid {
  mat-radio-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ededed;
    &:last-child {
      border: none;
    }
  }
  .mat-radio-label {
    width: 100%;
    display: flex;
    align-items: center;
    .mat-radio-label-content {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 60px;
      padding: 10px 30px 10px 59px;
      transition: all 0.3s ease-in-out;
    }
    .mat-radio-container {
      order: 1;
    }
    .mat-radio-container {
      display: none;
    }
  }
  .icon_img_wrap {
    width: 95px;
    display: flex;
    justify-content: center;
    margin-right: 58px;
  }
  .icon_img {
    max-width: 100%;
    max-height: 40px;
    &.sepa {
      height: 44px;
      max-height: 44px;
    }
    &.sofort {
      height: 27px;
    }
  }
  .payment_name {
    color: #979797;
    font-size: 18px;
    line-height: math.div(27, 18);
    transition: all 0.3s ease-in-out;
  }
  .check_icon {
    width: 24px;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  .mat-radio-checked .mat-radio-label-content {
    background-color: #ededed;
    .payment_name {
      color: $new_desc_color;
    }
    .check_icon {
      opacity: 1;
    }
  }
}

@include media_xs {
  .pay_grid {
    .payment_name {
      font-size: 16px;
      line-height: math.div(25, 16);
    }
    .mat-radio-label {
      .mat-radio-label-content {
        padding: 5px 17px 5px 13px;
        min-height: 50px;
      }
    }
    .icon_img_wrap {
      width: 82px;
      margin-right: 30px;
    }
    .icon_img {
      max-width: 100%;
      max-height: 22px;
      &.sepa {
        height: 22px;
        max-height: 22px;
      }
      &.sofort {
        height: 22px;
      }
    }
  }
}

// .regulations_wrap {
//   .mat-checkbox-frame {
//     border: 2px solid #acacac;
//   }
//   .mat-checkbox-background {
//     background-color: #fff;
//   }
// }

///sepa///
.sepa_mandate {
  .mat-checkbox-layout {
    align-items: flex-start;
    .mat-checkbox-inner-container {
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}
///end sepa///

///package///
.package_choise_cont,
.top_select_btns_cont {
  .swiper-container {
    width: 100%;
    overflow: visible;
  }
  .swiper-wrapper {
    box-sizing: border-box;
  }
}
///end package///

///custom lg checkbox///
mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  display: inline-block;
  height: 20px;
  line-height: 0;
  margin: auto 0.75rem auto 0.25rem;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 35px;
  height: 35px;
  flex-shrink: 0;

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 5px;

    svg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;

      path {
        stroke-linecap: round;
        stroke-width: 5;
        stroke-dasharray: 21;
      }
    }
  }
}

mat-checkbox.error .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
  border-color: $red_re;
}

mat-checkbox.mat-checkbox-disabled .mat-checkbox-layout .mat-checkbox-label {
  color: $input_label_color;
}
mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  font-size: 1rem;
}

.custom_lg_checkbox {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      color: $new_desc_color;
      font-size: 20px;
      font-weight: 600;
      line-height: math.div(30, 20);
    }
    .mat-checkbox-inner-container {
      margin-top: 5px;
      margin-right: 15px;
      width: 22px;
      height: 22px;
    }
  }
}
@include media_xs {
  .custom_lg_checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        font-size: 18px;
        line-height: math.div(22, 18);
      }
      .mat-checkbox-inner-container {
        margin-top: 3px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
///end of custom lg checkbox///

///intro modals///
.w_modal {
  h3 {
    strong {
      color: $prim_color_page;
      font-weight: 600;
    }
  }
  .subdesc {
    strong {
      color: $new_desc_color;
      font-weight: 600;
    }
  }
}
///intro modals///
///tooltip dialog box///
.cdk-global-overlay-wrapper {
  .tooltip_dialogbox {
    width: 500px;
    height: 100%;
    margin-left: auto;
    .mat-dialog-container {
      width: 100% !important;
      max-height: 100%;
      background: #f9fafb 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
      padding: 0;
      margin: 0;
    }
    app-more-info-tooltip-dialog {
      display: block;
      height: 100%;
    }
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .material-icons {
      font-size: 40px;
      width: auto;
      height: auto;
    }
    .material-icons {
      font-size: 40px;
    }
    .desc_wrap {
      color: $new_desc_color !important;
      font-size: 20px;
      line-height: math.div(30, 20);
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 11px 30px 0;
      p {
        color: $new_desc_color !important;
        font-size: 20px;
        line-height: math.div(30, 20);
        margin-bottom: 26px;
        strong {
          color: $new_desc_color !important;
          font-weight: 600;
        }
      }
      a {
        color: $new_desc_color !important;
        font-weight: 600;
        font-size: 20px;
        font-weight: 600;
        line-height: (30 * 0.05);
        text-decoration: underline;
        transition: 0.3s ease-in-out 0s;
        &:hover {
          opacity: 0.7;
        }
      }
      a.block_link {
        display: block;
        margin-bottom: 26px;
      }
    }
    .package_info_cont {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
    }
    .package_price_cont {
      .desc_wrap {
        overflow-y: visible;
        height: auto;
        margin-bottom: 2px;
      }
    }
    .package_price_wrap {
      position: relative;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      padding: 12px 30px 19px;
    }
    .price {
      color: $new_desc_color;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      line-height: 1.2;
      font-size: 22px;
      font-weight: 500;
      .lg_price {
        font-size: 60px;
        font-weight: 600;
        line-height: 1;
      }
      .cur,
      .sm_price {
        position: relative;
        top: 1px;
      }
    }
    .price_info {
      color: #979797;
      font-size: 14px;
      line-height: math.div(21, 14);
      text-align: center;
    }
    .package_info_btn {
      position: absolute;
      color: #fff;
      background-color: $new_desc_color;
      padding: 0 27px;
      min-width: 180px;
      max-width: 100%;
      min-height: 28px;
      margin: 0 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: math.div(21, 14);
      text-align: center;
      border-radius: 14px;
      left: 50%;
      bottom: -14px;
      transform: translateX(-50%);
    }
    .package_choise_list {
      padding: 28px 30px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 23px;
      }
      span {
        color: $new_desc_color;
        font-size: 18px;
        line-height: math.div(20, 18);
        max-width: 46%;
        &.underline {
          text-decoration: underline;
        }
      }
      img {
        width: 32px;
      }
    }
  }
}

@include media_xs {
  .cdk-global-overlay-wrapper {
    .tooltip_dialogbox {
      width: 100%;
      .mat-dialog-container {
        max-width: 100% !important;
        border-radius: 0 !important;
        height: 100% !important;
        max-height: 100% !important;
      }
      .material-icons {
        font-size: 25px;
      }
      .desc_wrap {
        padding: 0 16px;
      }
      .package_choise_list {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

////refund dialog box////
.cdk-global-overlay-wrapper {
  .refund_dialogbox {
    width: 1450px;
    max-width: 80%;
    height: 100%;
    margin-left: auto;
    .mat-dialog-container {
      width: 100% !important;
      max-width: 100% !important;
      max-height: 100%;
      background: #f9fafb 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
      padding: 0;
      margin: 0;
    }
    app-refund-dialog {
      display: block;
      height: 100%;
    }
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .material-icons {
      font-size: 40px;
      width: auto;
      height: auto;
    }
  }
}

@include media_xs {
  .cdk-global-overlay-wrapper {
    .refund_dialogbox {
      mat-dialog-container,
      app-refund-dialog {
        max-height: 100% !important;
        max-height: calc(var(--vh, 1vh) * 100) !important;
        border-radius: 0 !important;
      }
      .material-icons {
        font-size: 25px;
      }
    }
  }
}

///Alerts///
.alert_box {
  color: $new_desc_color;
  position: relative;
  width: 100%;
  max-width: 920px;
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
  font-size: 20px;
  line-height: (30 * 0.05);
  border-bottom: 2px solid $orange;
  padding: 14px 50px 10px 16px;
  &.red {
    border-color: $alert_red;
  }
  &.w_btns {
    display: flex;
    align-items: flex-start;
    padding-right: 16px;
  }
  p {
    margin: 0;
  }
  strong,
  b {
    font-weight: 600;
  }
  .close_btn_wrap {
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
  .alert_icon {
    width: 24px;
    margin-top: 3px;
  }
  .desc_wrap {
    max-width: calc(100% - 24px);
    padding-left: 10px;
    padding-right: 24px;
    .btc_error {
      padding-left: 6px;
    }
  }
  .btns_wrap {
    display: flex;
    align-items: stretch;
    margin-left: auto;
  }
  a {
    color: $new_desc_color;
    font-size: 20px;
    line-height: (30 * 0.05);
    font-weight: 600;
    text-decoration: none;
  }
}

@include media_from_to(1025px, 1250px) {
  .alert_box {
    &.w_btns {
      flex-wrap: wrap;
    }
    .btns_wrap {
      width: 100%;
      padding-top: 18px;
    }
  }
}

@include media_xs {
  .alert_box {
    font-size: 16px;
    .close_btn_wrap {
      top: 0;
      right: 0;
      transform: none;
    }
    &.w_btns {
      padding-top: 20px;
      padding-bottom: 18px;
      width: calc(100% + 32px);
      margin-left: -16px;
      font-size: 18px;
      line-height: math.div(27, 18);
      flex-wrap: wrap;
    }
    a {
      font-size: 18px;
      line-height: math.div(27, 18);
    }
    .btns_wrap {
      width: 100%;
      padding-top: 18px;
      justify-content: space-between;
    }
  }
}
///End of Alerts///

///atachments dialog boxes///
.attachments_dialogbox,
.downloads_dialogbox {
  .mat-dialog-container {
    max-width: 1023px !important;
    width: 90vw !important;
    border-radius: 10px;
    padding: 30px;
  }
  .mat-icon-button {
    .mat-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
    .material-icons {
      font-size: 32px;
    }
  }
}

.downloads_dialogbox {
  .mat-dialog-container {
    max-width: 848px !important;
  }
}

.wysiwyg {
  color: $new_desc_color !important;
  p {
    font-size: 20px;
    line-height: (30 * 0.05);
    margin: 0 0 10px;
  }
}

// Material tabs
.mat-tab-group {
  .mat-tab-header {
    border: none;
    margin-left: -0.5rem;
  }

  .mat-tab-label {
    border-radius: $border_radius_1;
    padding: 0.5rem;
    margin-right: 1.5rem;
    height: 39px;
    min-width: auto;

    &:focus {
      background: none !important;
    }

    &:before {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: 3px;
      border-radius: 2px;
      background: $prim_color_page;
      display: block;
      content: "";
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }

    .mat-tab-label-content {
      color: $new_desc_color;
      transition: font-weight 0.3s ease-in-out;
      font-size: 1.25rem;

      @include media_xs {
        font-size: 1rem;
      }
    }

    &.mat-tab-label-active {
      position: relative;
      opacity: 1;

      &:before {
        opacity: 1;
      }

      .mat-tab-label-content {
        font-weight: 600;
      }
    }
  }

  .mat-ink-bar {
    display: none;
  }
}

@include media_xs {
  .wysiwyg {
    p {
      font-size: 18px;
      line-height: math.div(27, 18);
    }
  }
}

@include media_xs {
  .attachments_dialogbox,
  .downloads_dialogbox {
    .mat-dialog-container {
      width: 100vw !important;
      height: 100vh !important;
      max-height: 100vh !important;
      height: calc(var(--vh, 1vh) * 100) !important;
      max-height: calc(var(--vh, 1vh) * 100) !important;
      margin: 0;
      padding: 30px 16px 16px;
      border-radius: 0 !important;
    }
  }
}

.attachments {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    .title_40 {
      max-width: calc(100% - 50px);
    }
  }
  &__single {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
    &_box {
      color: $new_desc_color;
      width: 56%;
      min-height: 50px;
      display: flex;
      align-items: center;
      background-color: $new_bg_grey;
      border: 1px solid #ededed;
      border-radius: 4px;
      padding: 5px 12px 5px 16px;
      font-size: 22px;
      line-height: 1.2;
      justify-content: space-between;
      @include transition(all 0.2s ease-out);
    }
    &_select {
      width: 36%;
    }
    &_name {
      max-width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_size {
      color: #979797;
      margin-left: 0.5rem;
      white-space: nowrap;
    }
    &_view {
      color: $new_desc_color;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      outline: none;
      margin-left: 1rem;
      span {
        margin-right: 14px;
      }
      img {
        width: 24px;
      }
    }
    &_year_wrap {
      color: #979797;
      font-size: 14px;
      line-height: 1.2;
      padding: 16px 16px 0;
      width: 100%;
      .attachments__single_size {
        display: none;
      }
    }
    &.downloads {
      .attachments__single_box {
        width: calc(100% - 66px);
      }
      .attachments__single_view {
        margin-left: auto;
      }
    }
  }
}

.attachments__single_box.error {
  border-color: $alert_red;
}

.attachments__single_box.form-error {
  @include borderBottomError();
}

.mat-select {
  background-color: $new_bg_grey;
  .mat-select-value {
    padding-left: 16px;
  }
}

@include media_sm {
  .attachments {
    &__single {
      &_box {
        width: calc(100% - 66px);
        margin-bottom: 16px;
      }
      &_select {
        order: 2;
        width: calc(100% - 66px);
      }
      &_year_wrap {
        order: 3;
      }
      &.downloads {
        .attachments__single_box {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media_xs {
  .attachments {
    &__dialog-box {
      padding-bottom: 30px;
    }
    &__header {
      margin-bottom: 30px;
    }
    &__single {
      &_box {
        order: 1;
        width: calc(100% - 60px);
        min-height: 48px;
        font-size: 16px;
        padding-left: 12px;
        padding-right: 12px;
      }
      &_select {
        width: calc(100% - 60px);
      }
      &_size {
        display: none;
      }
      &_year_wrap {
        order: 0;
        width: calc(100% - 60px);
        display: flex;
        justify-content: space-between;
        padding: 0 12px 4px;
        .attachments__single_size {
          display: block;
        }
      }
      &_name {
        max-width: 65%;
        padding-right: 10px;
      }
      &_view {
        font-size: 16px;
        span {
          margin-right: 8px;
        }
      }
      .btn_del,
      .btn_w_icon {
        order: 2;
      }
    }
  }
}

@media (max-width: 360px) {
  .attachments__box-action {
    justify-content: center;
  }

  .attachments {
    &__single {
      &.downloads {
        .attachments__single_box {
          width: 220px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .attachments {
    &__single {
      &.downloads {
        .attachments__single_box {
          width: 170px;
        }
      }
    }
  }
}

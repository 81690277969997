$spacer: 1rem;

$spacers: (
        0: 0,
        0_125: $spacer * .125,
        0_25: $spacer * .25,
        0_3125: $spacer * .3125,
        0_5: $spacer * .5,
        0_625: $spacer * .625,
        0_75: $spacer * .75,
        1: $spacer * 1,
        1_25: $spacer * 1.25,
        1_5: $spacer * 1.5,
        1_75: $spacer * 1.75,
        1_875: $spacer * 1.875,
        2: $spacer * 2,
        2_5: $spacer * 2.5,
        2_875: $spacer * 2.875,
        3: $spacer * 3,
        3_125: $spacer * 3.125,
        3_25: $spacer * 3.25,
        4: $spacer * 4,
        4_375: $spacer * 4.375,
        auto: auto,
);

@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $space in $spacers {
      .#{$abbrev}-#{$size} { #{$prop}: $space $space !important; }
      .#{$abbrev}x-#{$size} {
        #{$prop}-left: $space !important;
        #{$prop}-right: $space !important;
      }
      .#{$abbrev}y-#{$size} {
        #{$prop}-top: $space !important;
        #{$prop}-bottom: $space !important;
      }
      .#{$abbrev}t-#{$size} { #{$prop}-top:    $space !important; }
      .#{$abbrev}r-#{$size} { #{$prop}-right:  $space !important; }
      .#{$abbrev}b-#{$size} { #{$prop}-bottom: $space !important; }
      .#{$abbrev}l-#{$size} { #{$prop}-left:   $space !important; }

      .#{$abbrev}-n#{$size} { #{$prop}: - $space - $space !important; }
      .#{$abbrev}x-n#{$size} {
        #{$prop}-left: - $space !important;
        #{$prop}-right: - $space !important;
      }
      .#{$abbrev}y-n#{$size} {
        #{$prop}-top: - $space !important;
        #{$prop}-bottom: - $space !important;
      }
      .#{$abbrev}t-n#{$size} { #{$prop}-top:    - $space !important; }
      .#{$abbrev}r-n#{$size} { #{$prop}-right:  - $space !important; }
      .#{$abbrev}b-n#{$size} { #{$prop}-bottom: - $space !important; }
      .#{$abbrev}l-n#{$size} { #{$prop}-left:   - $space !important; }
    }
  }
}

@mixin spacing_sm() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $space in $spacers {
      @include media_to_sm {
        .#{$abbrev}-md-#{$size} { #{$prop}: $space $space !important; }
        .#{$abbrev}x-md-#{$size} {
          #{$prop}-left: $space !important;
          #{$prop}-right: $space !important;
        }
        .#{$abbrev}y-md-#{$size} {
          #{$prop}-top: $space !important;
          #{$prop}-bottom: $space !important;
        }
        .#{$abbrev}t-md-#{$size} { #{$prop}-top:    $space !important; }
        .#{$abbrev}r-md-#{$size} { #{$prop}-right:  $space !important; }
        .#{$abbrev}b-md-#{$size} { #{$prop}-bottom: $space !important; }
        .#{$abbrev}l-md-#{$size} { #{$prop}-left:   $space !important; }

        .#{$abbrev}-md-n#{$size} { #{$prop}: - $space - $space !important; }
        .#{$abbrev}x-md-n#{$size} {
          #{$prop}-left: - $space !important;
          #{$prop}-right: - $space !important;
        }
        .#{$abbrev}y-md-n#{$size} {
          #{$prop}-top: - $space !important;
          #{$prop}-bottom: - $space !important;
        }
        .#{$abbrev}t-md-n#{$size} { #{$prop}-top:    - $space !important; }
        .#{$abbrev}r-md-n#{$size} { #{$prop}-right:  - $space !important; }
        .#{$abbrev}b-md-n#{$size} { #{$prop}-bottom: - $space !important; }
        .#{$abbrev}l-md-n#{$size} { #{$prop}-left:   - $space !important; }
      }
    }
  }
}

@include spacing;
@include spacing_sm;

.card {
  border: 1px solid $input_border_color;
  border-radius: $border_radius_1;
  padding: 1rem 1rem 0 1rem;

  &.card-white {
    background-color: $white;
  }

  &.card-input {
    background-color: $input_color;
    box-shadow: none;
    color: $new_desc_color;
  }
}

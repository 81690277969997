$gray: #878787;
$disabled_field: #ebebe4;
$dark_disabled_field: #7b7b79;
$dark_text: #07181f;
$gray_light: #949494;
$gray_light2: #d3d3d3;
$gray_dark: #444444;
$gray_re: #e1e1e1;
$gray_sdark_re: #8a8a8a;
$gray_dark_re: #404040;
$orange: #ffa002;
$green: #00c853;
$green_new: #42b651;
$green_re: #2dcc3a;
$green_lh_re: #e7ffe7;
$green_box: #ddfedc;
$red: #FF0000;
$red_re: #be181c;
//$muted_green: #86cca3;
$dialog_background_green: #03c752;
$green_desc: #009548;
$text_color: #96999b;
$placeholder_color: #949494;
$warning: #e85729;
$gray_bg: #f7f9fa;
$gray_bg2: #fcfcfc;
$alert_text: #d3dbe0;
$error_color: #fd3f74;
$button_dark: #0f2028;
$button_dark_hover: #132932;
$tool-bar_bg: #F9FAFB;
$white: #ffffff;
$border_color: #f1f3f4;
$border_color2: #cbcbcb;
$light_green: #85cca3;
$mobile_dark_bg: #f1f2f3;
$search_btn: #b9bdbe;
$input_color: #F9FAFB;
$input_text_color: #C4C6C7;
$input_border_color: #EDEDED;
$input_bg_disabled: #EDEDED;
$gray_new: #e6e6e6;
//menu
$menu_bg: #0f2027;
$menu_stage: #a0a5a6;

$gray_acco: #c2c2c2;
$gray_del: #231f20;

$package_btn_color:#DEE9F7;
$package_btn_color_no_active: #878787;
$package_bg: #F6F7F8;
$package_opt: #828B94;

$new_bg_grey: #FCFCFC;

//$new_desc_color: #112B35;
$new_desc_color: #10272F;
$new_gray_color: #8C8C8C;
$new_green_color: #3AD55A;

$input_label_color: #979797;

$orange: #E86200;
$tiles_border: #C5C9CA;

$red_list: #E61F1F;
$alert_red: #E61F1F;
$red_light: #fff9f9;

//$plus_value_color: #12AD32;
//$prim_color_page: #2EEDC7;
$plus_value_color: #18806B;
$prim_color_page: #12CBA6;
$sec_color_page: #dffdf7;
$btn_color: #3BAFDB;
$btn_txt_color: #FFFFFF;
$completed_stages: #18806B;
$muted_green: #2EEDC7;
$bg_primary_with_opacity: rgba(0, 201, 82, 0.3);

$border_grey: #DADDDE;
$bg_note_color: #F8F8F8;

@import './colors';
@import './mixins';

$border-width: 2px;

.btn {
  border-radius: 4px !important;
  font-weight: 400 !important;
  display: inline-block;
  cursor: pointer !important;
  height: 45px;
  line-height: 45px - 2 * $border-width;
  font-size: 14px;
  padding: 0 20px;
  text-align: center;
  color: $dark_text;
  @include transition(all 0.2s ease-out);
  text-decoration: none !important;
  position: relative;

  @include until($phone) {
    font-size: 12px;
  }

  &:after {
    @include transition(all 0.2s ease-out);
    border: 2px solid transparent;
  }

  &:disabled, button:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  &--primary {
    background-color: $prim_color_page !important;
    border: $border-width solid $prim_color_page !important;
    box-shadow: 2px 2px 3px 1px #eee;
    color: $btn_txt_color !important;
    font-weight: bold !important;

    &:not(:disabled):hover {
      color: $prim_color_page !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  &--danger {
    border: $border-width solid $alert_red !important;
    font-weight: bold !important;
    color: $alert_red !important;
    background: transparent !important;
    box-shadow: none !important;

    &:not(:disabled):hover {
      background-color: $alert_red !important;
      border: $border-width solid $alert_red !important;
      box-shadow: 2px 2px 3px 1px #eee;
      color: $btn_txt_color !important;
    }
  }

  &--upper {
    text-transform: uppercase;
  }

  &--small {
    height: 30px;
    line-height: 30px - 2 * $border-width;
    box-shadow: none !important;
    font-weight: bold !important;

    &.btn--longer {
      padding: 0 35px;
    }
  }

  &--xsmall {
    height: 22px;
    line-height: 22px - 2 * $border-width;
    box-shadow: none !important;
    font-weight: bold !important;

    &.btn--longer {
      padding: 0 30px;
    }
  }

  &--info {
    background-color: transparent;
    border: $border-width solid $prim_color_page !important;
    color: $prim_color_page !important;
  }

  &--secondary {
    background-color: transparent;
    border: $border-width solid black !important;
    color: black !important;
    font-weight: bold !important;
    opacity: 0.7;

    &:not(:disabled):hover {
      opacity: 1;
    }
  }

  &--white {
    background-color: transparent;
    border: $border-width solid white !important;
    box-shadow: 2px 2px 3px 1px #eee;
    color: white !important;

    &:hover {
      opacity: 0.7;
    }
  }

  &--no-border {
    border: none !important;
  }

  &--block {
    width: 100%;
  }

  &--is-link {

    &:hover {
      cursor: pointer;
      text-decoration: underline !important;
      color: $gray_dark;
    }
  }

  &--text {

    &:hover {
      cursor: pointer;
      color: $gray_dark;
    }
  }

  &--longer {
    padding: 0 80px;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }

  &.is-loading {
    color: transparent !important;

    &:after {
      -webkit-animation: spinAround 500ms infinite linear;
      animation: spinAround 500ms infinite linear;
      border: 2px solid #dbdbdb;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      position: absolute !important;
    }
  }

  &--icon {
    border: none !important;
    padding: 0 !important;
    display: inline-flex;

    .fa {

      @include until($tablet) {
        font-size: 14px;
      }
    }
  }

  &--close {
    font-size: 50px;
    width: 50px;
    height: 50px;
    background: $gray_bg;
    text-align: center;
    line-height: 0;
    border: 0px solid transparent;
    cursor: pointer;
    @include transition(all 0.2s ease-out);

    @include until($tablet) {
      height: 40px;
      width: 40px;
      background: transparent;
    }

    &-x {
      @include transform(rotate(45deg));
      display: inline-block;
      font-style: initial;
      position: relative;
      top: -2px;
      left: 3px;
      display: block;
      font-weight: 300;
    }

    &:hover {
      @include transform(scale(0.9));
    }
  }

  &--back {

    @include until($tablet) {
      height: 35px;
      border: 0 solid transparent !important;
      margin-right: 10px;
      padding: 5px 4px !important;
    }

    .btn--img {
      width: 29px;
      height: 21px;
      position: relative;
      top: 2px;
      background: url("./assets/img/tools_back.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: none;

      @include until($tablet) {
        display: inline-block;
      }
    }

    .btn--tx {
      @include until($tablet) {
        display: none;
      }
    }
  }

  &--add {
    border: none;
    background: transparent;
    font-weight: 600 !important;
    font-size: 15px;
    display: flex;
    @include until($tablet) {
      padding: 0;
    }
    &::before {
      background-image: url("./assets/svg/add.svg");
      width: 2.5rem;
      height: 2.5rem;
      content: ' ';
      display: inline-block;
      text-decoration: inherit;
      font-size: 22px;
      padding-right: 20px;
    }
  }

  .mat-button-focus-overlay {
    display: none;
  }
}

.btn-remove {
  position: relative;
  padding: 6px 5px 6px 20px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0;
  background: transparent;

  @include until($tablet) {
    flex-direction: column;
  }

  &:before {
    content: ' ';
    display: inline-block;
    left: 0;
    top: 6px;
    width: 2.5rem;
    height: 2.5rem;
    background-image: url("./assets/svg/delete_simply_gray.svg");
  }
}

.btn-dark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  background-color: $button_dark;
  text-transform: uppercase;
  min-width: 180px;
  height: 60px;
  border: none;

  &:hover {
    background-color: $button_dark_hover;
  }

  img {
    width: 30px;
    height: 30px;
    max-width: initial;
  }
}

.btn-add-outline {
  width: 100%;
  min-height: 50px;
  padding: 5px 20px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid $prim_color_page;
  border-radius: 4px;
  & > img {
    width: 30px;
    height: 100%;
  }
  & > span {
    color: $prim_color_page;
    font-size: 20px;
    font-weight: 600;
    padding-left: 16px;
    max-width: 77%;
  }

  &, img, span {
    @include transition(all 0.2s ease-out);
  }

  &:hover {
    background-color: $prim_color_page;

    & > span {
      color: $btn_txt_color;
    }
  }
}

.btn-file {
  input[type=file] {
    display: none;
  }
}

@include media_xs {
  .btn-add-outline {
    width: 100%;
    min-height: 48px;
    & > img {
      width: 20px;
    }
    & > span {
      font-size: 14px;
      padding-left: 10px;
      max-width: inherit;
    }
  }
}

.button-fill-primary.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0;
}

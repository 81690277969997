$header_padding_top: 0;
$header_height: 70px;
$aside_width: 360px;
$content_width: 1000px;
$content_margin_x: 6%;
$footer_height: 40px;
$tools_bar_height: 80px;
$min_content_height: calc(100vh - #{$footer_height + $header_height + $header_padding_top});
$border_radius_1: 4px;
$border_radius_2: 10px;
$border_radius_3: 16px;
$box_shadow_1: 0 3px 10px rgba(0, 0, 0, 0.11);

// Trsutpilot
$trustpilot_colors: (
  0: #DCDCE6,
  1: #FF3524,
  2: #FF8525,
  3: #FECE00,
  4: #75CE10,
  5: #00B77B,
);

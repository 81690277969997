@import "node_modules/bulma/sass/utilities/mixins";

$phone: 400px !default;
$tablet: 750px !default;
$desktop: 1008px !default;
$widescreen: 1200px !default;
$fullhd: 1392px !default;


@mixin transition($args) {
  transition: $args;
}

@mixin borderBottomError() {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  border-bottom-color: $red_re !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@mixin transform($args) {
  transform: $args;
}

@mixin between($arg1, $arg2) {
  @media (min-width: $arg1) and (max-width: $arg2) {
    @content;
  }
}

@mixin clearfix() {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

///epicup mixins///

@mixin hover{
  @media screen and (min-width: 1025px){
      &:hover{
          @content;
      }
  }
}

@mixin media_to($width: 1199px){
  @media screen and (max-width: $width) {
      @content;
  }
}
@mixin media_from($min: 1025px) {
  @media screen and (min-width: $min) {
      @content;
  }
}
@mixin media_from_to($min: 1025px, $max: 1199px) {
  @media screen and (min-width: $min) and (max-width: $max) {
      @content;
  }
}
@mixin media_sm{
  @media screen and (max-width: 1024px) {
      @content;
  }
}
@mixin media_to_sm {
  @media screen and (min-width: 1025px) {
      @content;
  }
}
@mixin media_only_sm {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
      @content;
  }
}
@mixin media_xs{
  @media screen and (max-width: 767px) {
      @content;
  }
}
@mixin media_to_xs {
  @media screen and (min-width: 768px) {
      @content;
  }
}

@mixin inputLoader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $prim_color_page;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  width: 1em;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
  position: absolute !important;
  z-index: 1;
}

/* open-sans-300 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('open-sans-v27-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('open-sans-v27-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('open-sans-v27-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('open-sans-v27-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-800.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('open-sans-v27-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('open-sans-v27-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('open-sans-v27-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('open-sans-v27-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('open-sans-v27-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('open-sans-v27-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('open-sans-v27-latin_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

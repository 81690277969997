// slide toggle
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: $prim_color_page;
}

// checkbox

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-checked.mat-accent .mdc-checkbox__background,
.mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background,
.mat-radio-checked.mat-accent .mat-radio-persistent-ripple {
  background-color: $prim_color_page !important;
}

mat-checkbox .mat-checkbox-ripple.mat-ripple .mat-ripple-element,
mat-radio-button .mat-radio-ripple.mat-ripple .mat-ripple-element {
  background: rgba($prim_color_page, 0.3) !important;
}

mat-checkbox.mat-checkbox .mat-checkbox-ripple {
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;

  .mat-ripple-element:nth-child(2) {
    opacity: .6;
    left: calc(50% - 25px) !important;
    top: calc(50% - 25px) !important;
    height: 50px !important;
    width: 50px !important;
  }
}

.full-screen-modal {
  max-width: unset !important;
  width: 100% !important;
  height: 100% !important;

  .mat-dialog-container {
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100% !important;
    width: 100% !important;
    border-radius: 0 !important;
    margin: 0 !important;

    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

@use "sass:math";
@import "./colors";
@import "./mixins";
@import "./spacing-helper";
@import "src/vars";

$content_width_mini: 600px;

@include media_to(320px) {
  $tools_bar_height: 70px;
}

html {
  overflow-y: auto;
}

.content {
  @extend .content--full-width;
  max-width: $content_width;
  width: 100%;

  @include until($desktop) {
    max-width: none;
  }
}

.content--full-center {
  @extend .content--full-width;
  margin: 0 10% !important;
}

.content--centered {
  @extend .content;
  margin: auto !important;

  @include until($tablet) {
    padding: 40px 0 0 !important;
  }
}

.content--full-width {
  margin: 0 6%;
  padding: 50px 0;
  flex: 1;

  @include until($desktop) {
    margin: 0 !important;
    padding: 25px;
  }
}

.form-content {
  //background: #fcfcfc !important;
  padding: 20px;
}

.info {
  &-ico {
    width: 16px;
    height: 16px;
    border: 1px solid $prim_color_page;
    border-radius: 50%;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    line-height: 15px;
    color: $prim_color_page;
  }

  &-text {
    display: inline-block;
    margin-left: 6px;
  }
}

.deductions-content {
  @media screen and (max-width: 749px) {
    padding: 10px !important;
  }
}

.tooltip-row {
  text-align: right;
  &.left {
    text-align: left !important;
    padding: 5px 0;
  }
}

.big-label-container {
  margin-top: 25px;

  &__label {
    font-size: 22px;
    padding-bottom: 5px;

    @include until($tablet) {
      font-size: 18px;
    }
  }

  &__input {
    @include from($desktop) {
      width: 50%;
    }
  }
}

.container-user-not-logged {
  @include until($desktop) {
    padding-top: 50px;
  }
}

.nested {
  margin: 0 !important;
}

.svg-icon {
  display: inline-flex;
  align-items: center;

  &__img {
    width: 2.5rem;
    height: 2.5rem;
  }

  &__label {
    font-size: 14px;
  }
}

///tiles global///
.ep_new_tiles_wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  max-width: 1030px;
  &.top {
    align-items: flex-start;
  }
  .ep_new_single_tile_wrap {
    position: relative;
    width: 25%;
    padding: 0 15px 30px;
    & > button.button-delete {
      right: 24px;
    }
    & > .tooltip_info_wrap {
      position: absolute;
      bottom: 10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
    }
    .tooltip_w_desc_wrap {
      padding: 8px 15px 0;
    }
  }
  button.button-delete {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
    display: block;
    width: 23px;
    height: 23px;
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid $prim_color_page;
  }
  .delete_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    display: none;
    z-index: 1;
    cursor: pointer;
    img {
      width: 100%;
    }
    &.visible {
      display: block;
      right: 26px;
    }
    &.after {
      display: block;
      @include media_to_xs {
        right: 26px;
      }
    }
  }
  .disable_tile {
    position: absolute;
    left: 15px;
    top: 0;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    z-index: 1;
    cursor: pointer;
  }
}
.ep_new_single_tile {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  //min-height: 200px;
  border: 1px solid $tiles_border;
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  padding: 0;

  &.active {
    border-color: $prim_color_page;
    background-color: $sec_color_page;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    .delete_icon,
    .delete_icon_inset {
      display: block;
    }
  }
  &:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  }
  @include hover {
    &:not(.isDisabled) {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
  }
  &.isDisabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.is-loading {
    cursor: wait;
    opacity: 0.1;
  }
  &.error {
    border-color: $red;
  }
  .ep_desc_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 109px 0 15px;
    display: flex;
    align-items: center;
    &.wrap {
      flex-wrap: wrap;
    }
    &.slg {
      padding-top: 123px;
    }
  }
  .icon_tile_wrap {
    position: absolute;
    top: 33px;
    left: 0;
    width: 100%;
    //min-height: 65px;
    //margin-bottom: 29px;
    // &.sm_m {
    //   margin-bottom: 17px;
    // }
    &.slg {
      .icon_tile {
        max-width: 80px;
        max-height: 80px;
      }
    }
  }
  .icon_tile {
    max-width: 65px;
    max-height: 65px;
    display: block;
    margin: 0 auto;
  }
  .title_tile_sm {
    color: $new_desc_color;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 0 10px;
    margin: 0 auto;
    text-decoration: none;
    display: block;
    .green_button_text {
      color: $prim_color_page;
    }
  }
  .title_tile_lg {
    width: 100%;
    color: $new_desc_color;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    padding: 0 10px;
    text-decoration: none;
  }
  .inputs_wrap {
    width: 100%;
    padding: 0 20px;
    margin-top: 10px;
  }
  .single_input {
    padding-top: 10px;
    border-top: 1px solid $prim_color_page;
  }
}

@include media_from_to(768px, 1500px) {
  .ep_new_tiles_wrap {
    .ep_new_single_tile_wrap {
      width: 255px;
    }
  }
}

@include media_xs {
  .ep_new_tiles_wrap {
    width: 100%;
    margin-left: 0;
    .ep_new_single_tile_wrap {
      width: 100%;
      margin: 0 0 16px;
      padding: 0;
      & > button.button-delete {
        right: 10px;
      }
      & > .tooltip_info_wrap {
        bottom: -15px;
      }
    }
    button.button-delete {
      width: 23px;
      height: 23px;
      top: calc(50% - 12px);
      right: 10px;
    }
    .delete_icon {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      right: 0;
      transform: translateX(-50%);
      background-color: #fff;
      border-radius: 50%;
      &.visible {
        right: 0;
      }
    }
    .disable_tile {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .ep_new_single_tile {
    padding: 0;
    margin: 0;
    height: 100%;

    .ep_desc_wrap {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 70px;
      padding: 10px 40px 10px 0;
      &.slg {
        padding: 10px 40px 10px 0;
      }
      &.profil_custom {
        padding-right: 12px;
      }
    }
    .icon_tile_wrap {
      position: relative;
      top: 0;
      width: 70px;
      padding-left: 12px;
      min-height: auto;
      margin-bottom: 0;
      &.slg {
        .icon_tile {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
    .icon_tile {
      max-width: 40px;
      max-height: 40px;
      margin: 0;
    }
    .title_tile_sm,
    .title_tile_lg {
      width: calc(100% - 70px);
      text-align: left;
      padding: 0;
    }
    .title_tile_lg {
      font-size: 26px;
      line-height: 1.2;
      padding-top: 3px;
    }
    .inputs_wrap {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.ep_tiles_wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
  max-width: 1030px;
  .ep_single_tile_wrap {
    position: relative;
    width: 25%;
    padding: 0 15px 30px;
    &.no_pad {
      padding: 0;
    }
  }
  &.top {
    align-items: flex-start;
  }
  &.no_pad {
    width: 100%;
    margin-left: 0;
    .ep_single_tile_wrap {
      padding: 0;
    }
  }
  .tooltip_info_wrap {
    position: absolute;
    bottom: -19px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 1;
  }
  .delete_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    display: none;
    z-index: 1;
    cursor: pointer;
    img {
      width: 100%;
    }
    &.visible {
      display: block;
      right: 26px;
    }
    &.after {
      display: block;
      @include media_to_xs {
        right: 26px;
      }
    }
  }
}

.ep_single_tile {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 33px 0 15px;
  width: 100%;
  height: 100%;
  min-height: 200px;
  border: 1px solid $tiles_border;
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  text-decoration: none;
  @include hover {
    &:not(.isDisabled) {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
  }
  &.isDisabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.is-loading {
    cursor: wait;
    opacity: 0.1;
  }
  &.active {
    border-color: $prim_color_page;
    background-color: $sec_color_page;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    &.bot.is_input {
      padding-top: 30px;
      padding-bottom: 10px;
    }
    .delete_icon,
    .delete_icon_inset {
      display: block;
    }
  }
  &.error {
    border-color: $red;
  }
  &.center {
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ep_desc_wrap {
    width: 100%;
    max-width: 176px;
    display: flex;
    flex-direction: column;
  }
  .icon_tile_wrap {
    height: 94px;
    &.slg {
      height: auto;
      .icon_tile {
        max-width: 80px;
        max-height: none;
      }
    }
  }
  .icon_tile {
    max-width: 65px;
    max-height: 65px;
    display: block;
    margin: 0 auto;
  }
  .title_tile_lg {
    width: 100%;
    color: $new_desc_color;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  .title_tile_sm {
    color: $new_desc_color;
    font-size: 18px;
    font-weight: 600;
    line-height: math.div(22, 18);
    margin: 0;
    text-align: center;
  }
  h3,
  h4 {
    margin: 0;
  }
  // &.top {
  //   align-items: flex-start;
  //   padding-top: 30px;
  //   padding-bottom: 20px;
  // }
  // &.bot {
  //   align-items: flex-end;
  //   padding: 10px 10px 34px;
  // }
  .inputs_wrap {
    padding-top: 12px;
    margin-top: 14px;
    border-top: 1px solid $prim_color_page;
  }
  .delete_icon_inset {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 24px;
    display: none;
    z-index: 1;
    cursor: pointer;
    img {
      width: 100%;
    }
    &.after {
      display: block;
      @include media_to_xs {
        right: 9px;
      }
    }
  }
}

@include media_xs {
  .ep_tiles_wrap {
    width: 100%;
    margin-left: 0;
    .ep_single_tile_wrap {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }
    .tooltip_info_wrap {
      bottom: -13px;
    }
    .delete_icon {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      right: -20px;
      transform: translateX(-50%);
      background-color: #fff;
      border-radius: 50%;
      &.visible {
        right: -20px;
      }
    }
  }

  .ep_single_tile {
    padding: 0;
    margin: 0;
    height: 100%;
    min-height: inherit;
    .ep_desc_wrap {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 70px;
      padding: 5px 40px 5px 0;
      &.slg {
        padding: 5px 40px 5px 0;
      }
    }
    .icon_tile_wrap {
      position: relative;
      top: 0;
      width: 70px;
      height: auto;
      min-height: auto;
      padding-left: 12px;
      margin-bottom: 0;
      &.slg {
        .icon_tile {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
    .icon_tile {
      max-width: 40px;
      max-height: 40px;
      margin: 0;
    }
    .title_tile_sm,
    .title_tile_lg {
      width: calc(100% - 70px);
      text-align: left;
      padding: 0;
    }
    .title_tile_lg {
      font-size: 26px;
      line-height: 1.2;
      padding-top: 3px;
    }
    .delete_icon {
      width: 20px;
      height: 20px;
      top: calc(50% - 10px);
      right: 0;
      transform: translateX(-50%);
      background-color: #fff;
      border-radius: 50%;
      &.visible {
        right: 0;
      }
    }
  }
}
///tiles global///

///titles global///
.selected_wrapper {
  color: $new_desc_color;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 18px;
}

.header_wrap {
  margin-bottom: 28px;
}

.title_w_tooltip {
  margin-bottom: 15px;
  .title_42 {
    display: inline-block;
    margin: 0;
  }
  .tooltip_wrap {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    top: 2px;
  }
}

.tooltip_w_desc_wrap {
  color: $new_desc_color;
  font-size: 18px;
  line-height: math.div(27, 18);
  display: flex;
  align-items: center;
  justify-content: center;
  .desc {
    padding-right: 10px;
  }
  .tooltip_wrap {
    position: relative;
    top: 4px;
  }
}

.desc_w_tooltip {
  display: flex;
  align-items: center;
  .tooltip_wrap {
    display: inline-block;
    margin-left: 12px;
    position: relative;
    top: -2px;
  }
}

.title_42 {
  color: $new_desc_color !important;
  font-size: 42px;
  line-height: math.div(50, 42);
  letter-spacing: 0;
  font-weight: 600;
  margin: 0;
}

.title_40 {
  color: $new_desc_color !important;
  font-size: 40px;
  line-height: math.div(60, 40);
  font-weight: 600;
  letter-spacing: 0;
  margin: 0;
}

.title_33 {
  color: $new_desc_color;
  font-size: 33px;
  font-weight: 400;
  letter-spacing: 0.66px;
  margin: 0;
}

.title_30 {
  color: $new_desc_color !important;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
}
.title_24 {
  color: $new_desc_color !important;
  font-size: 24px;
  line-height: math.div(35, 24);
  font-weight: 600;
  margin: 0;
  strong {
    font-weight: bold;
  }
}

.title_20 {
  color: $new_desc_color !important;
  font-size: 20px;
  line-height: math.div(30, 20);
  font-weight: 600;
  margin: 0;
  strong {
    font-weight: bold;
  }
}

.subdesc {
  color: $new_desc_color !important;
  font-size: 20px;
  line-height: math.div(30, 20);
  margin: 0;
  strong {
    color: $new_desc_color;
    font-weight: 600;
  }
  &.f_18 {
    font-size: 18px;
    margin: 20px 0;
  }
}

.desc_20 {
  color: $new_desc_color;
  font-size: 20px;
  line-height: math.div(30, 20);
  margin: 0;
}

.desc_16 {
  color: $new_desc_color;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
}

.ep_header {
  color: $prim_color_page;
  border-bottom: 1px solid $prim_color_page;
  padding-top: 8px;
  padding-bottom: 15px;
  margin-bottom: 23px;
  .ep_title {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.52px;
    margin: 0;
  }
  .ep_sub {
    font-size: 18px;
    letter-spacing: 0.36px;
  }
  .right_item {
    display: none;
  }
}

.form_header_wrap {
  margin-bottom: 24px;
  .title_30 {
    margin-bottom: 17px;
  }

  .title_42 {
    margin-bottom: 10px;
  }

  .subdesc {
    margin-bottom: 30px;
  }

  img {
    width: 200px;
  }
}

@include media_sm {
  .title_w_tooltip {
    .tooltip_wrap {
      top: 5px;
    }
  }
  .title_42 {
    font-size: 29px;
    line-height: 1;
  }
}

@include media_xs {
  .selected_wrapper {
    font-size: 22px;
    margin-bottom: 9px;
  }

  .title_w_tooltip {
    margin-bottom: 10px;
    .tooltip_wrap {
      top: 4px;
      margin-left: 8px;
    }
  }
  .title_42 {
    font-size: 24px;
    line-height: math.div(35, 24);
  }

  .title_40 {
    font-size: 24px;
    line-height: math.div(35, 24);
  }

  .title_33 {
    font-size: 18px;
    letter-spacing: 0.36px;
  }

  .title_30 {
    font-size: 20px;
    line-height: math.div(22, 20);
  }
  .title_24 {
    font-size: 20px;
    line-height: math.div(30, 20);
  }

  .subdesc {
    font-size: 18px;
    line-height: math.div(22, 18);
    &.f_18 {
      font-size: 14px;
    }
  }

  .ep_header {
    padding-bottom: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .left_item {
      width: calc(100% - 20px);
      padding-right: 10px;
    }
    .right_item {
      width: 20px;
      display: block;
      img {
        width: 100%;
      }
      .icon_down {
        display: none;
      }
    }
    .ep_title {
      font-size: 14px;
      letter-spacing: 0.28px;
    }
    .ep_sub {
      font-size: 9px;
      letter-spacing: 0.18px;
    }
    &.isCollapse {
      .right_item {
        .icon_up {
          display: none;
        }
        .icon_down {
          display: block;
        }
      }
    }
  }

  .header_wrap {
    margin-bottom: 31px;
  }

  .form_header_wrap {
    .title_30 {
      margin-bottom: 8px;
    }
    .title_42 {
      margin-bottom: 8px;
    }
    .subdesc {
      margin-bottom: 20px;
    }
    img {
      width: 140px;
    }
  }
}
///end titles global///

///form wrap///

.ep_form_wrap {
  padding: 22px 30px 20px;
  //background-color: $gray_bg;
  margin-bottom: 20px;
  .form_activities_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 16px;
  }
  .form_title {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.55px;
    width: calc(100% - 100px);
  }
  .btn_remove {
    max-width: 100px;
    position: relative;
    color: $gray_del;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.28px;
    padding-right: 30px;
    padding-top: 3px;
    margin-top: 5px;
    display: block;
    cursor: pointer;
    &::before {
      content: "";
      background-image: url("assets/svg/delete_simply_gray_new.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 3px;
      left: auto;
    }
  }
}

@include media_xs {
  .ep_form_wrap {
    padding: 10px 10px 0;
    .form_activities_header {
      padding-bottom: 11px;
      align-items: flex-start;
    }
    .form_title {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.2;
      width: calc(100% - 100px);
    }
    .btn_remove {
      margin-top: 0px;
      max-width: 95px;
    }
    .columns {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
///end form wrap///

///buttons///
.ep_new_form {
  min-height: 50px;
  background-color: $sec_color_page;
  display: flex;
  align-items: center;
  padding-left: 215px;
  &.is_spouse {
    margin-top: 30px;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
  .btn {
    .svg-icon {
      &__label {
        text-transform: uppercase;
        padding-top: 1px;
      }
      &__img {
        width: 30px;
        margin-left: 12px;
      }
    }
  }
}

@include media_to(1260px) {
  .ep_new_form {
    justify-content: center;
    padding-left: 0px;
  }
}

@include media_xs {
  .ep_new_form {
    min-height: 38px;
    .btn {
      .svg-icon {
        &__label {
          font-size: 11px;
          letter-spacing: 0.55px;
          line-height: 1.2;
        }
        &__img {
          width: 20px;
        }
      }
    }
  }
}
.btn_simple_gr {
  width: calc(100% + 16px);
  margin-left: -8px;
  display: flex;
  .btn_simple {
    margin: 0 8px;
  }
}
.btn_simple {
  color: $new_desc_color;
  cursor: pointer;
  min-width: 200px;
  height: 50px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #c5c9ca;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  padding: 0 20px;
  &.normal {
    text-transform: none;
  }
  &:hover {
    background-color: $sec_color_page;
    border-color: $prim_color_page;
  }
  &.active {
    background-color: $sec_color_page;
    border-color: $prim_color_page;
  }
  &.green {
    color: $prim_color_page;
    border-color: $prim_color_page;

    &__fill {
      background: $prim_color_page;
      color: $sec_color_page;
      border-color: $prim_color_page;

      &:hover {
        background-color: $sec_color_page !important;
        color: $prim_color_page !important;
      }
    }
  }
  &.black {
    border-color: $new_desc_color;
    &:hover {
      opacity: 0.7;
      background-color: #fff;
    }
  }
  &.danger {
    color: $alert_red;
    border-color: $alert_red;
    &:hover {
      opacity: 0.7;
      background-color: #fff;
    }
  }
  &.no_mw {
    min-width: auto;
    padding: 0 15px;
  }
  &.mw_130 {
    min-width: 130px;
    padding: 0 15px;
  }
  &.w_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      margin-right: 7px;
    }
  }
}
@include media_xs {
  .btn_simple {
    min-width: 130px;
    height: 45px;
    font-size: 12px;
    &.custom_mobile {
      font-size: 20px;
      height: auto;
      min-height: 40px;
      padding: 0 10px;
    }
  }

  .btn_simple_gr {
    width: calc(100% + 18px);
    margin-left: -9px;

    .btn_simple {
      width: calc(50% - 18px);
      height: 50px;
      font-size: 20px;
    }
  }
}
@include media_to(320px) {
  .btn_simple {
    &.custom_mobile {
      font-size: 16px;
    }
  }
}

.btn_w_icon {
  cursor: pointer;
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid $new_desc_color;
  border-radius: 4px;
  padding: 0;
  img {
    width: 32px;
  }
  &.btn_del {
    border-color: #e61f1f;
  }
}

@include media-xs {
  .btn_w_icon {
    width: 48px;
    height: 48px;
    img {
      width: 30px;
    }
  }
}

.form_add_btn {
  color: $prim_color_page;
  cursor: pointer;
  width: 100%;
  min-height: 50px;
  border: 1px solid $prim_color_page;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: math.div(30, 20);
  font-weight: 600;
  background-color: #fff;
  @include transition(all 0.3s ease-out);
  img {
    width: 44px;
    margin-right: 8px;
  }
  &:hover {
    background-color: $sec_color_page;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: $input_bg_disabled;
    border-color: $input_label_color;
    color: $input_label_color;
  }
}

@include media_xs {
  .form_add_btn {
    min-height: 48px;
    font-size: 18px;
    line-height: 1.2;
    img {
      width: 38px;
      margin-right: 5px;
    }
  }
}
.btn_simple_arrow {
  min-width: 176px;
  min-height: 40px;
  border: 1px solid $prim_color_page;
  color: $prim_color_page;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  cursor: pointer;
  svg {
    width: 24px;
    margin-left: 8px;
    .a {
      fill: none;
    }
    .b {
      fill: $prim_color_page;
      transition: all 0.3s ease-in-out 0s;
    }
  }
  @include hover {
    background-color: $prim_color_page;
    color: #fff;
    svg {
      .b {
        fill: #fff;
      }
    }
  }
}

.btn_arrow {
  color: #fff;
  min-width: 140px;
  min-height: 40px;
  border: 1px solid $prim_color_page;
  background-color: $prim_color_page;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in-out 0s;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  line-height: 1.2;
  svg {
    width: 32px;
    margin-left: 11px;
  }
  .a {
    fill: none;
  }
  .b {
    fill: #fff;
    transition: all 0.3s ease-in-out 0s;
  }
  @include hover {
    color: $prim_color_page;
    background-color: #fff;
    .b {
      fill: $prim_color_page;
    }
  }
}

.btn_sm {
  min-width: 120px;
  min-height: 50px;
  background-color: #fff;
  border: 1px solid $new_desc_color;
  border-radius: 4px;
  color: $new_desc_color;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: 0.3s ease-in-out 0s;
  cursor: pointer;
  @include hover {
    opacity: 0.7;
  }
}

.btn_icon {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $new_desc_color;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  white-space: nowrap;
  display: flex !important;
  justify-content: flex-start;

  img {
    width: 20px;
    margin-right: 8px;
  }
  &.red {
    color: $red_list;
  }
  &.is-loading {
    cursor: wait;
    opacity: 0.5;
  }
  .mat-button-focus-overlay {
    background-color: transparent;
  }

  &.downloads {
    span {
      margin-right: 5px;
    }
    img {
      margin: 0;
    }
  }

  &.hamburger-button {
    white-space: normal !important;
    word-break: break-word !important;
    span {
      display: block !important;
      width: 128px !important;
      text-align: left !important;
    }
  }
}

@include media_xs {
  .btn_icon {
    font-size: 16px;
    img {
      margin-right: 4px;
    }
  }
}

///btn add///
.btn_add_form {
  cursor: pointer;
  color: $prim_color_page;
  font-size: 20px;
  font-weight: 600;
  line-height: math.div(30, 20);
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $prim_color_page;
  border-radius: 4px;
  input {
    display: none;
  }
  img {
    width: 42px;
    margin-right: 11px;
  }
}

@include media_xs {
  .btn_add_form {
    font-size: 18px;
    line-height: math.div(27, 18);
    min-height: 48px;
    img {
      margin-right: 8px;
    }
  }
}

///dialog box///
.ep_btn_remove {
  position: relative;
  color: $gray_del;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.28px;
  padding-right: 30px;
  padding-top: 3px;
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
  &::before {
    content: "";
    background-image: url("assets/svg/delete_simply_gray_new.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1px;
    right: 0px;
    left: auto;
  }
}

.dialog-box {
  &:after {
    animation: spinAround 500ms infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 2rem;
    width: 2rem;
    left: calc(50% - (2rem / 2));
    top: calc(50% - (2rem / 2));
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }
}

.dialog-box.is-loading {
  min-width: 300px;
  min-height: 300px;

  &:after {
    opacity: 1;
  }
}

.ep_dialog_box {
  .title {
    color: #404040 !important;
    text-align: left;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.6px;
    border-bottom: 1px solid $prim_color_page;
    padding-right: 30px !important;
    position: relative;
    margin-top: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
    line-height: 1.2;
    padding-bottom: 13px;
    button {
      position: absolute;
      right: -14px;
      top: 0;
      .mat-icon {
        color: $prim_color_page;
      }
    }
    img {
      height: 35px;
      width: auto;
      margin-right: 15px;
    }
  }
  .btn--primary {
    text-transform: uppercase;
    background-color: $prim_color_page;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19px;
    letter-spacing: 0.7px;
  }
  .btn--secondary {
    border: none !important;
    text-transform: uppercase;
    color: #231f20 !important;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 19px;
    letter-spacing: 0.7px;
    margin-right: 37px;
  }

  .description {
    display: flex;
    justify-content: flex-end;
  }

  .m_top {
    margin-top: 30px;
  }

  .dialog-box {
    &__bg {
      padding: 10px 20px 30px;
      background-color: transparent;
      margin-bottom: 30px;
      &.top_m {
        margin-top: 30px;
      }
      &.m_p_top {
        padding-top: 30px;
      }
    }
    &__add-wrap {
      text-align: center;
      background-color: $sec_color_page;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 20px;
      button {
        align-items: center;
        color: $prim_color_page;
        font-size: 13px;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.65px;
        position: relative;
        padding-top: 3px;
        padding-right: 45px;
        line-height: 1.2;
        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          @include transform(translateY(-50%));
        }
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 16px;

      &-title {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0.55px;
        text-align: left;
        padding-right: 20px;
      }
    }

    &__wrapper {
      &:not(:last-child) {
        .dialog-box__add-wrap {
          margin-bottom: 30px;
        }
        &.m_bot {
          margin-bottom: 30px;
        }
      }
    }

    &__subwrapper {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .input_row {
      margin-bottom: 20px;
    }
  }

  .columns {
    .column {
      &.info_box {
        display: flex;
        align-items: flex-end;
        > div {
          margin-bottom: 0;
        }
      }
    }
  }
}
@include media-xs {
  .ep_dialog_box {
    .deductions-content {
      padding: 15px !important;
    }
    .btn--primary,
    .btn--secondary {
      letter-spacing: 0.005em;
      font-size: 14px;
      height: 38px;
    }
    .btn--primary {
      padding: 0 55px;
    }
    .btn--secondary {
      margin-right: 0;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      margin-top: 6px;
      margin-bottom: 5px !important;
      padding-bottom: 6px;
      img {
        height: 20px;
        margin-right: 10px;
        margin-top: 0;
      }
      button {
        top: -18px;
        right: -11px;
      }
    }

    .m_top {
      margin-top: 20px;
    }

    .dialog-box {
      &__bg {
        padding: 10px 10px 20px;
        margin-bottom: 20px;
        &.top_m {
          margin-top: 20px;
        }
        &.m_p_top {
          padding-top: 20px;
        }
      }
      &__header {
        &-title {
          color: #231f20;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          padding-right: 25px;
        }
        .ep_btn_remove {
          margin-top: 0px;
        }
      }
      &__wrapper {
        .dialog-box__add-wrap {
          margin-top: 20px;
        }
        &:not(:last-child) {
          .dialog-box__add-wrap {
            margin-bottom: 20px;
          }
          &.m_bot {
            margin-bottom: 20px;
          }
        }
      }
      &__subwrapper {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &__form-buttons {
        padding-top: 40px;
        text-align: right;
      }
      &__form {
        padding-top: 15px;
      }
      &__add-wrap {
        height: auto;
        min-height: 38px;
        padding: 0 10px;
        button {
          color: $prim_color_page;
          font-size: 11px;
          line-height: 1.2;
          letter-spacing: 0.55px;
          position: relative;
          padding: 10px 35px 10px 0;
          height: auto;
          min-height: 38px;
          display: flex;
          align-items: center;
          text-align: left;
          &::before {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .columns {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      margin-top: -0.5rem;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      .column {
        padding: 0.5rem;
        &.empty {
          display: none;
        }
        &.info_box {
          justify-content: flex-end;
          padding-top: 0;
        }
      }
    }
  }
}
///end dialog box///

@include media_xs {
  .dialogbox_full_width_xs {
    width: 100%;
    .mat-dialog-container {
      margin: 0 !important;
      max-width: 100% !important;
      border-radius: 0 !important;
      height: 100% !important;
      max-height: 100% !important;
    }
  }
}
///end dialog box///

.main-content__container {
  margin: 0 $content_margin_x;
  padding: 3.375rem 0 7rem;
  flex: 1;
  max-width: $content_width;
  width: 100%;

  &__mini {
    max-width: $content_width_mini;
  }

  @include until($desktop) {
    margin: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media_xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.form-radio-button-input {
  &.mat-radio-checked label {
    background-color: $sec_color_page;
    border-color: $prim_color_page;
    box-shadow: $box_shadow_1;
  }

  label {
    line-height: 1.25;
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $border_grey;
    border-radius: $border_radius_1;
    box-shadow: none;
    height: auto;
    padding: 1.25rem;
    width: 100%;
    margin: 0 0 1.25rem;
    transition:
      background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;

    .mat-radio-label-content {
      padding-left: 1rem;
    }

    p {
      white-space: normal;

      &:nth-of-type(1) {
        font-weight: 600;
        font-size: 1.25rem;
        margin-bottom: 0.25rem;

        @include media_xs {
          font-size: 1.125rem;
        }
      }

      &:nth-of-type(2) {
        color: $input_label_color;
        font-size: 1.125rem;

        @include media_xs {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.backdropBackgroundWhite {
  background-color: #fff;
}

.new-dialog-wrap .mat-dialog-container {
  border-radius: $border_radius_2;
  border: 1px solid $border_grey;
}

.login_dialog_wrap .mat-dialog-container {
  max-width: 1025px !important;
  box-shadow: none;
  width: auto !important;
}

.personal-info-dialog-wrap .mat-dialog-container {
  max-width: 800px !important;
  width: 100vw !important;
}

.mat-checkbox-danger {
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background,
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $alert_red !important;
  }

  .mat-ripple-element {
    background: rgba($alert_red, 0.3) !important;
  }
}

.text-primary {
  color: $prim_color_page !important;
}

.text-danger {
  color: $alert_red !important;
}

.text-danger--dark {
  color: $red_re !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: 500 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-16 {
  font-size: 1rem !important;
}

.font-size-18 {
  font-size: 1.125rem !important;

  @include media_xs {
    font-size: 0.875rem !important;
  }
}

.font-size-20 {
  font-size: 1.25rem !important;

  @include media_xs {
    font-size: 1rem !important;
  }
}

.font-size-22 {
  font-size: 1.375rem !important;

  @include media_xs {
    font-size: 1.125rem !important;
  }
}

.font-size-24 {
  font-size: 1.5rem !important;

  @include media_xs {
    font-size: 1.25rem !important;
  }
}

.font-size-28 {
  font-size: 1.75rem !important;

  @include media_xs {
    font-size: 1.5rem !important;
  }
}

.font-size-30 {
  font-size: 1.875rem !important;

  @include media_xs {
    font-size: 1.625rem !important;
  }
}

.font-size-40 {
  font-size: 2.5rem !important;

  @include media_xs {
    font-size: 1.75rem !important;
  }
}

.font-size-42 {
  line-height: 1.2;
  font-size: 2.625rem !important;

  @include media_xs {
    font-size: 2.125rem !important;
  }
}

@include media_xs {
  .font-size-mobile-20 {
    font-size: 1.25rem !important;
  }

  .font-size-mobile-18 {
    font-size: 1.125rem !important;
  }

  .font-size-mobile-16 {
    font-size: 1rem !important;
  }

  .font-size-mobile-14 {
    font-size: 0.875rem !important;
  }
}

.position-relative {
  position: relative !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-grey {
  background-color: $gray !important;
}

.bg-grey--muted {
  background-color: $gray_light2 !important;
}

.bg-note {
  background-color: $bg_note_color !important;
}

.rounded {
  border-radius: $border_radius_1 !important;
}

.rounded-top {
  border-radius: $border_radius_1 $border_radius_1 0 0 !important;
}

.rounded-2 {
  border-radius: $border_radius_2 !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.text-primary {
  color: $prim_color_page !important;
}

.text-muted {
  color: $input_label_color !important;
}

.text-center {
  text-align: center !important;
}

.v-align-mid {
  vertical-align: middle !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  min-height: 100% !important;
}

.mh-100vh {
  min-height: 100vh !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: end !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.w-auto {
  width: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@include media_to {
  .hidden_md {
    display: none !important;
  }
}

@include media_sm {
  .hidden_sm {
    display: none !important;
  }
}

@include media_to_sm {
  .hidden_above_sm {
    display: none !important;
  }

  .column-content-v-center {
    padding-top: 2.275rem;
    display: flex;
    align-items: center;
  }
}
